
import { PaganiniMenu } from "./navigation";
import { ProjectsSlideshow } from "./slideshow";
import { gsap } from "gsap";

document.addEventListener("DOMContentLoaded", function () {
  const stories = document.querySelector("#stories");
  const popups = new Map();

  const slideshow = new ProjectsSlideshow(stories, 10000, (label: string) => {
    if (label === 'gods-untold') {
      const url = 'https://www.kickstarter.com/projects/paganinistories/gods-untold-an-anthology-of-gods-from-around-the-world';
      window.open(url, '_blank');
    } else {
      const popup = document.querySelector('.popup');
      popup.classList.add('active');

      const project = popup.querySelector(`.${label}`);
      project.classList.remove('inactive');

      slideshow.stop();
    }
  });

  slideshow.start();

  const labels = Array.from(stories.querySelectorAll('.project')).map((story) => {
    return story.getAttribute('label');
  });

  for (const label of labels) {
    const popup = document.querySelector(`div.project-popup.${label}`);
    const popupSlideshow = new ProjectsSlideshow(popup, 10000, (label: string) => {});
    popups.set(label, popupSlideshow);
  }

  const close = document.querySelector("div.pop-up-content .close");
  close.addEventListener('click', () => {
    const popup = document.querySelector('.popup');
    popup.classList.remove('active');

    const projects = Array.from(popup.querySelectorAll('.project-popup'));
    for (const project of projects) {
      project.classList.add('inactive');
    }

    slideshow.start();
  });

  const menu = new PaganiniMenu(slideshow);
});

setupLogoAnimation();
function setupLogoAnimation() {
  let requestId: number = null;
  const tl = new gsap.core.Timeline({paused:true});

  tl.fromTo(".logo", 800, {y: 0}, { y: -100 }, 0);

  const container = document.getElementsByTagName("main");
  container[0].addEventListener("scroll", function() {
    if (!requestId) {
      requestId = requestAnimationFrame(update);
    }
  });

  function update() {
    tl.time(container[0].scrollTop);
    requestId = null;
  }
}