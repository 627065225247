import { gsap } from "gsap";
import { ProjectsSlideshow } from "./slideshow";

export class PaganiniMenu {
  mobile: boolean = window.innerWidth < 700;
  private menu: HTMLElement = document.querySelector(".navigation");
  private subMenu: HTMLElement = document.querySelector(".sub-menu-projects");
  private openMenuButton: HTMLElement =
    this.menu.querySelector(".nav-open-mobile");
  private closeMenuButton: HTMLElement =
    this.menu.querySelector(".nav-close-mobile");
  private pages: HTMLElement[] = Array.from(
    document.querySelectorAll("section.page")
  );
  private closestPage: HTMLElement = undefined;
  private menuItems: HTMLElement[] = Array.from(
    document.querySelectorAll(".navigation .nav-button ")
  );
  private projectsMenuBtn: HTMLElement = document.querySelector(".nav-stories");
  private timeout: number = 0;

  constructor(private readonly slideshow: ProjectsSlideshow) {
    this.setEventListeners();
    this.setMobileClass();
  }

  private setEventListeners() {
    window.addEventListener("resize", this.onWindowResizeHandler.bind(this));
    document
      .querySelector("main")
      .addEventListener("scroll", this.onScrollHandler.bind(this));
    for (const btn of this.menuItems) {
      btn.addEventListener("click", () => {
        this.scrollToPage(btn.getAttribute("page"));
        if (btn.getAttribute('page') !== 'stories') {
          this.tiggerCollapseMenu();
          this.menu.classList.remove('expanded');
        }
      });
    }
    this.projectsMenuBtn.addEventListener(
      "mouseover",
      this.triggerExpandMenu.bind(this)
    );
    this.subMenu.addEventListener(
      "mouseleave",
      this.tiggerCollapseMenu.bind(this)
    );

    this.openMenuButton.addEventListener('click', () => {
      this.menu.classList.add('expanded');
    });

    this.closeMenuButton.addEventListener('click', () => {
      this.menu.classList.remove('expanded');
    });

    const projectBtns = Array.from(
      this.menu.querySelectorAll(".sub-nav-button")
    );
    for (const btn of projectBtns) {
      btn.addEventListener("click", () => {
        this.slideshow.goToLabel(btn.getAttribute("label"));
        this.scrollToPage(this.pages[0].id);
        this.tiggerCollapseMenu();
        this.menu.classList.remove('expanded');
      });
    }
  }

  private triggerExpandMenu() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.expandMenu.bind(this), 300);
  }

  private tiggerCollapseMenu() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.collapseMenu.bind(this), 300);
  }

  expandMenu() {
    this.menu.classList.add('sub-menu-visible');
    this.subMenu.classList.add('visible');
  }

  collapseMenu() {
    this.menu.classList.remove('sub-menu-visible');
    this.subMenu.classList.remove('visible');
  }

  private onWindowResizeHandler(event: Event) {
    this.mobile = window.innerWidth < 700;
    this.setMobileClass();
  }

  private setMobileClass() {
    if (this.mobile) {
      this.menu.classList.add("mobile");
    } else {
      this.menu.classList.remove("mobile");
    }
  }

  private onScrollHandler() {
    let closest = undefined;
    let minY = Number.MAX_VALUE;
    for (const item of this.pages) {
      const y = Math.abs(item.getBoundingClientRect().y);
      if (y < minY) {
        closest = item;
        minY = y;
      }
    }

    if (closest != this.closestPage) {
      this.closestPage = closest;
      this.updateActiveMenuItem();
    }
  }

  private updateActiveMenuItem() {
    document.querySelector(".nav-button.active").classList.remove("active");
    document
      .querySelector(`.nav-button[page=${this.closestPage.id}]`)
      .classList.add("active");
  }

  private scrollToPage(page: string) {
    const pageElement = document.getElementById(page);
    pageElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }
}
