import { gsap } from "gsap";

enum SlideAnimationDirection {
  LEFT = 100,
  RIGHT = -100,
}

export class ProjectsSlideshow {
  private progressContainer;
  private progressBullets: HTMLElement[] = [];
  private slides = [] as HTMLElement[];
  private nextBtn;
  private prevBtn;
  private moreBtn;
  private closeBtn = document.querySelector('.pop-up-content .close');
  private interval:  number | undefined = undefined;
  private animating: boolean = false;
  private index = 0;
  private animationOptions = {
    ease: "power4.inOut",
    duration: 1.5,
  };
  private timeline = new gsap.core.Timeline({repeat: Infinity});
  private labels: string [] = [];

  constructor(private readonly container: Element, private readonly delayUntilNextSlide: number, private readonly openPopupHandler: Function) {
    this.nextBtn = this.container.querySelector(".next-btn");
    this.prevBtn = this.container.querySelector(".prev-btn");
    this.moreBtn = this.container.querySelector(".more");
    this.progressContainer = this.container.querySelector('.progress-bullets');
    this.slides = Array.from(
      this.container.querySelectorAll(".project")
    ) as HTMLElement[];
    this.nextBtn.addEventListener("click", this.nextSlide.bind(this));
    this.prevBtn.addEventListener("click", this.prevSlide.bind(this));
    this.moreBtn.addEventListener("click", this.moreBtnHandler.bind(this));
    this.setProgressBullets();
    this.setupTimeline();
    this.timeline.pause(this.labels.at(0));
    this.updateProgressIndex();

    if (this.slides.length < 2) {
      this.nextBtn.classList.add('hidden');
      this.prevBtn.classList.add('hidden');
      this.progressContainer.classList.add('hidden');
    }
    this.container.setAttribute('label', this.timeline.currentLabel());
  }

  moreBtnHandler() {
    this.openPopupHandler(this.labels[this.index]);
  }

  setProgressBullets() {
    const bulletTemplate = this.progressContainer.querySelector('.bullet');
    for (let i = 1; i < this.slides.length; i++) {
      this.progressContainer.appendChild(bulletTemplate.cloneNode(true));
    }
    this.progressBullets = Array.from(this.progressContainer.querySelectorAll('.bullet'));
  }

  updateProgressIndex() {
    const activeBullet = this.progressContainer.querySelector('.bullet.active');
    activeBullet?.classList.remove('active');
    this.progressBullets[this.index]?.classList?.add('active');
  }

  setupTimeline() {
    this.timeline.addLabel('start');
    for (let i = 0; i < this.slides.length; i++) {
      const currSlide = this.slides.at(i);
      const prevSlide = this.slides.at(i - 1);
      const nextSlide = this.slides.at((i + 1) % this.slides.length);
      const label = currSlide.getAttribute('label');

      this.labels.push(label);

      this.timeline.fromTo(currSlide, {x: '100vw'}, {x: '0vw', ...this.animationOptions});
      this.timeline.fromTo(prevSlide, {x: '0vw'}, {x: '-100vw', ...this.animationOptions}, "<");
      this.timeline.fromTo(nextSlide, {x: '200vw'}, {x: '100vw', ...this.animationOptions}, "<");
      this.timeline.fromTo(this.nextBtn, {x: '0vw', opacity: 1}, {x: '10vw', opacity: 0}, "<");
      this.timeline.fromTo(this.prevBtn, {x: '0vw', opacity: 1}, {x: '-10vw', opacity: 0}, "<");
      this.timeline.fromTo(this.closeBtn, {scale: 1, opacity: 1}, {scale: 0.8, opacity: 0, ease: "expo.out"}, "<");
      this.timeline.fromTo(this.moreBtn, {scale: 1, opacity: 1}, {scale: 0.8, opacity: 0, ease: "expo.out"}, "<");
      this.timeline.fromTo(this.nextBtn, {x: '10vw', opacity: 0}, {x: '0vw', opacity: 1});
      this.timeline.fromTo(this.prevBtn, {x: '-10vw', opacity: 0}, {x: '0vw', opacity: 1}, "<");
      this.timeline.fromTo(this.moreBtn, {scale: 1.3, opacity: 0}, {scale: 1, opacity: 1, ease:"bounce.out"}, "<");
      this.timeline.fromTo(this.closeBtn, {scale: 0.8, opacity: 0}, {scale: 1, opacity: 1, ease:"bounce.out"}, "<");
      this.timeline.call(this.setLabel.bind(this), [label], "<");

      this.timeline.addLabel(label);
    }

    const firstSlide = this.slides.at(0);
    const lastSlide = this.slides.at(-1);

    this.timeline.fromTo(lastSlide, {x: '0vw'}, {x: '-100vw', ...this.animationOptions});
    this.timeline.fromTo(firstSlide, {x: '100vw'}, {x: '0vw', ...this.animationOptions}, "<");
    this.timeline.fromTo(this.nextBtn, {x: '0vw'}, {x: '10vw'}, "<");
    this.timeline.fromTo(this.prevBtn, {x: '0vw'}, {x: '-10vw'}, "<");

    this.timeline.fromTo(this.nextBtn, {x: '10vw'}, {x: '0vw'});
    this.timeline.fromTo(this.prevBtn, {x: '-10vw'}, {x: '0vw'}, "<");


    this.timeline.addLabel('end');

    this.timeline.seek('end', true);
  }

  setLabel(label: string) {
    // console.log(`LABEL: ${label}, CURR: ${this.timeline.currentLabel()}`);
    this.container.setAttribute('label', label);
  }

  slideAnimationOnComplete() {
    this.animating = false;
    this.timeline.pause();
    this.updateProgressIndex();
  }

  goToLabel(label: string) {
    const labelIndex = this.labels.indexOf(label);
    if (labelIndex !== -1) {
      this.resetInterval();
      this.animating = true;
      this.timeline.timeScale(4);
      this.timeline.tweenTo(label, {onComplete: () => {
        this.animating = false;
        this.index = labelIndex;
        this.updateProgressIndex();
        this.timeline.timeScale(1);
      }});
    } else {
      throw Error(`Label ${label} not found in slideshow.`);
    }
  }

  start() {
    this.interval = setInterval(
      this.nextSlide.bind(this),
      this.delayUntilNextSlide
    );
  }

  stop() {
    clearInterval(this.interval);
  }

  resetInterval() {
    if (this.interval) {
      this.stop();
      this.start();
    }
  }

  nextSlide() {
    if (this.animating) {
      return;
    }
    this.animating = true;
    this.resetInterval();

    let fromLabel = this.labels.at(this.index);
    this.index = this.index === this.slides.length - 1 ? 0 : this.index + 1;
    const toLabel = this.labels.at(this.index);

    if (fromLabel === this.labels.at(-1)) {
      fromLabel = 'start';
    }
    this.timeline.tweenFromTo(fromLabel, toLabel, {onComplete: this.slideAnimationOnComplete.bind(this)});
  }

  prevSlide() {
    if (this.animating) {
      return;
    }
    this.animating = true;
    this.resetInterval();

    let fromLabel = this.labels.at(this.index);
    this.index = this.index === 0 ? this.slides.length - 1 : this.index - 1;
    const toLabel = this.labels.at(this.index);

    if (fromLabel === this.labels.at(0)) {
      fromLabel = 'end';
    }

    this.timeline.tweenFromTo(fromLabel, toLabel, {onComplete: this.slideAnimationOnComplete.bind(this)});
  }
}